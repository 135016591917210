<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Orphan Tasks</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Workload"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search By Team Leader"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTeamLead"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.workChunkForm.openForm()"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Work Block</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="work_chunks"
            no-data-text="No work chunks found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'module-workload-individual',
                  params: { chunkId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>
            <template v-slot:item.customer.name="{ item }">
              <router-link
                v-if="item.customer"
                :to="{
                  name: 'customer-profile-basic',
                  params: { customerId: item.customer.id },
                }"
                >{{ item.customer.name }}</router-link
              >
            </template>
            <template v-slot:item.team_member="{ item }">
              <div v-if="item.team_member">
                {{ item.team_member.user.first_name }}
                {{ item.team_member.user.last_name }}
              </div>
              <div v-if="!item.team_member" class="red--text">N/A</div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.workChunkForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="
                      archiveWorkloadDialog = true;
                      archiveWorkloadData = item;
                    "
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="archiveWorkloadDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Workload</v-card-title>
        <v-card-text>
          Are you sure you want to delete
          {{ archiveWorkloadData ? archiveWorkloadData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archiveWorkloadDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveWorkloadLoading"
            @click="archiveWorkload(archiveWorkloadData.id)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addLogTimeForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="addLogTime" method="post">
          <v-card-title>
            <span class="headline">Log Time</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Title"
                  type="text"
                  v-model="addLogTimeForm.title"
                  outlined
                  dense
                  :error="addLogTimeForm.errors.hasOwnProperty('title')"
                  :error-messages="addLogTimeForm.errors['title']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Minutes"
                  type="number"
                  v-model="addLogTimeForm.minutes"
                  outlined
                  dense
                  :error="addLogTimeForm.errors.hasOwnProperty('minutes')"
                  :error-messages="addLogTimeForm.errors['minutes']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Chunk"
                  :items="work_chunks"
                  v-model="addLogTimeForm.chunk_id"
                  item-text="name"
                  item-value="id"
                  :return-object="false"
                  outlined
                  dense
                  :error="addLogTimeForm.errors.hasOwnProperty('chunk_id')"
                  :error-messages="addLogTimeForm.errors['chunk_id']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="addLogTimeForm.date"
                      label="Date"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addLogTimeForm.date"
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  type="text"
                  v-model="addLogTimeForm.notes"
                  outlined
                  dense
                  :error="addLogTimeForm.errors.hasOwnProperty('notes')"
                  :error-messages="addLogTimeForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="addLogTimeForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addLogTimeForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <WorkChunkForm
      ref="workChunkForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
    />
  </div>
</template>

<script>
import WorkChunkForm from "/src/modules/workload/views/chunks/components/WorkChunkForm.vue";

export default {
  components: {
    WorkChunkForm,
  },

  data() {
    return {
      datePicker: false,
      statuses: ["Open", "Closed", "Deleted"],
      types: ["Project", "Ad-hoc", "Unknown", "Contract", "Diary"],
      searchTerm: "",
      searchTeamLead: "",
      breadcrumbs: [
        {
          text: "Workload",
          disabled: true,
        },
        {
          text: "Orphan Tasks",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Customer", value: "customer.name" },
        { text: "Owner", value: "team_member" },
        { text: "Hours Logged", value: "total_time_logged.text" },
        { text: "To Do Date", value: "formatted_dates.to_do" },
        { text: "Work group", value: "groups" },
        { text: "Actions", value: "actions", align: "right" },
      ],

      addWorkGroupForm: {
        dialog: false,
        name: null,
        loading: false,
        errors: {},
      },
      addLogTimeForm: {
        dialog: false,
        date: new Date().toISOString().slice(0, 10),
        notes: null,
        title: null,
        minutes: 0,
        chunk_id: null,
        loading: false,
        errors: {},
      },

      archiveWorkloadDialog: false,
      archiveWorkloadLoading: false,
      archiveWorkloadData: null,
    };
  },

  computed: {
    work_chunks() {
      let work_chunks = this.$store.getters["workload/notInGroupWorkChunks"];

      if (this.searchTerm !== "") {
        work_chunks = work_chunks.filter((c) => {
          const name = c.name.toLowerCase();
          const description = c.description ? c.description.toLowerCase() : "";
          const customer =
            c.customer !== null ? c.customer.name.toLowerCase() : "";
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            description.includes(this.searchTerm.toLowerCase()) ||
            customer.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      if (this.searchTeamLead !== "") {
        work_chunks = work_chunks.filter((c) => {
          const teamLeader =
            c.team_member !== null ? c.team_member.full_name.toLowerCase() : "";
          return teamLeader.includes(this.searchTeamLead.toLowerCase());
        });
      }

      return work_chunks;
    },
    groups() {
      return this.$store.state.workload["groups"];
    },

    groupsFiltred() {
      let groups = this.$store.state.workload["groups"];

      return groups;
    },

    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },
  },

  methods: {
    archiveWorkload: function (id) {
      const appId = this.$route.params.id;
      this.archiveWorkloadLoading = true;

      this.$store
        .dispatch("workload/archiveWorkload", {
          appId,
          id,
        })
        .then(() => {
          this.archiveWorkloadData = null;
          this.archiveWorkloadLoading = false;
          this.archiveWorkloadDialog = false;
        });
    },

    addLogTime: function () {
      const appId = this.$route.params.id;
      const date = this.addLogTimeForm.date;
      const notes = this.addLogTimeForm.notes;
      const title = this.addLogTimeForm.title;
      const minutes = this.addLogTimeForm.minutes;
      const chunk_id = this.addLogTimeForm.chunk_id;

      this.addLogTimeForm.loading = true;
      this.addLogTimeForm.errors = {};

      this.$store
        .dispatch("workload/logTime", {
          appId,
          date,
          notes,
          title,
          minutes,
          chunk_id,
        })
        .then(() => {
          this.addLogTimeForm = {
            dialog: false,
            date: null,
            notes: null,
            title: null,
            minutes: 0,
            hours: 0,
            chunk_id: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addLogTimeForm.errors = err.response.data.errors;
          this.addLogTimeForm.loading = false;
        });
    },
  },
};
</script>
